.content_select[data-v-b061548c] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textColor[data-v-b061548c] {
  margin: 0 6px;
  cursor: pointer;
  font-size: 14px;
}
.selected[data-v-b061548c] {
  color: #17A2B8;
}
.drawerHead[data-v-b061548c] {
  display: flex;
  justify-content: space-between;
}
.cosName[data-v-b061548c] {
  cursor: pointer;
}
.cosName[data-v-b061548c]:hover {
  color: #17A2B8;
}
.page_container[data-v-b061548c] {
  float: right;
  margin-top: 10px;
}
.yesYw[data-v-b061548c] .el-cascader--small {
  width: 234px!important;
}
