@charset "UTF-8";
[data-v-4df0bc79].hide .el-upload--picture-card {
  display: none;
}
[data-v-4df0bc79] .el-list-enter-active,[data-v-4df0bc79] .el-list-leave-active {
  transition: all 0s;
}
[data-v-4df0bc79] .el-list-enter, .el-list-leave-active[data-v-4df0bc79] {
  opacity: 0;
  transform: translateY(0);
}
[data-v-4df0bc79] .el-upload--picture-card {
  --el-upload-picture-card-size: 80px!important;
}

/* 确保图片自适应对话框大小 */
.preview-image[data-v-4df0bc79] {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}