.formTitle[data-v-7c560380] {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 14px;
}
.w200[data-v-7c560380] {
  width: 200px;
}
.drawerHead[data-v-7c560380] {
  display: flex;
  justify-content: space-between;
}
.up[data-v-7c560380] {
  margin: 2px 0 0 3px;
  transform: rotate(-90deg);
  cursor: pointer;
  z-index: 9999;
}
.down[data-v-7c560380] {
  margin: 2px 0 0 3px;
  transform: rotate(-90deg);
  cursor: pointer;
  z-index: 9999;
}
[data-v-7c560380] .el-collapse {
  border: none;
}
[data-v-7c560380] .el-collapse-item__arrow {
  margin: 0 8px 0 5px!important;
}
[data-v-7c560380] .el-collapse-item:first-of-type .el-collapse-item__header .el-collapse-item__arrow {
  display: none;
}
.margin20[data-v-7c560380] {
  margin-top: 20px;
}
.content[data-v-7c560380] {
  width: 100%;
  overflow-x: auto;
  /* 启用水平滚动条 */
  white-space: nowrap;
  /* 防止内容换行 */
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content span[data-v-7c560380] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-7c560380] {
  padding: 0;
  margin: 0;
}
.content td[data-v-7c560380] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 32px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.centers[data-v-7c560380] {
  text-align: center;
  height: 30px;
  width: 100%;
}
[data-v-7c560380] .el-input-group__append {
  padding: 0 6px;
  cursor: pointer;
}
.ht[data-v-7c560380] .el-collapse-item__wrap {
  overflow-x: auto!important;
}
.scroll-container[data-v-7c560380] {
  overflow-x: auto;
  /* 允许水平滚动 */
  max-width: 100%;
  position: relative;
}
.no-data-container[data-v-7c560380] {
  position: fixed;
  /* 固定在视口 */
  top: 50%;
  /* 垂直居中 */
  left: 50%;
  /* 水平居中（需要配合 transform） */
  transform: translate(-50%, -50%);
  /* 调整位置以正确居中 */
  text-align: center;
  /* 文本居中 */
  width: 100%;
  /* 或其他适当的宽度，但这里使用 100% 会导致水平居中失效，需要调整 */
}
